<template>
  <v-row v-if="fullCMandQS && fullCMandQS.length > 0" class="mt-4">
    <v-card class="w-100">
      <div class="p-3 pb-0 subtitle-1 d-flex align-center">
        <span class="panel-title" v-text="'Suivi des justificatifs médicaux'" />
      </div>
      <v-simple-table class="mt-3">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left header-data">Justificatif</th>
              <th class="text-center header-data">Type</th>
              <th class="text-center header-data">Saisons Couvertes</th>
              <th class="text-center header-data">Date de fin de validité</th>
              <th class="text-center header-data">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in fullCMandQS"
              :key="index"
              @click="test(item)"
            >
            <template>
              <td>
                <span class="text-normal">
                  {{
                    item.Categorie === "Questionnaire santé"
                      ? "Questionnaire santé"
                      : "Certificat médical"
                  }}
                </span>
              </td>
              <td class="text-center">
                <span
                  class="text-normal"
                  v-text="
                    item.Categorie === 'Questionnaire santé'
                      ? '/'
                      : item.LIST_DocumentType.NomTypeDocument
                  "
                />
              </td>
              <td class="text-center">
                <div>
                  <v-chip
                    v-for="(saison, index) in getCoveredSeason(item)"
                    :key="index"
                    outlined
                    color="primary"
                    class="mx-1 text-normal"
                  >
                    {{ saison }}
                  </v-chip>
                </div>
              </td>
              <td class="text-center">
                <span class="text-normal">
                  {{
                    moment(item.DateFinValiditeDocument).format("DD/MM/YYYY")
                  }}</span
                >
              </td>

              <td class="text-center">
                <v-icon
                  v-if="item.Categorie !== 'Questionnaire santé'"
                  @click="downloadCertificat(item)"
                  color="#020918"
                  >mdi-download
                </v-icon>
                <v-icon
                    @click="showDialog(item)"
                    color="#020918"
                    v-if="canDeleteThisDoc(item)"
                >mdi-delete
                </v-icon>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        color="gray"
                    >mdi-delete
                    </v-icon>
                  </template>
                  <span>Suppression impossible : un questionnaire santé a été rempli au cours de la période</span>
                </v-tooltip>
              </td>
            </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <YesNo
      id="yesno-dialog"
      title="Confirmez la suppression"
      text="Souhaitez-vous vraiment supprimer ce document ?"
      maxWidth="360"
      :showDialog="confirmDialog"
      @answerYes="deleteCertificatMedical"
      @answerNo="confirmDialog = false"
      text_no="Annuler"
      text_yes="Supprimer"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YesNo from "@/components/utils/modals/yes-no.vue";

import moment from "moment-timezone";
import {error_notification} from "@/plugins/notifications";
import RoleTypes from "@/constants/roles";
import { waitingDocument } from "@/constants/licenceStatus";
import { getLicences } from "@/plugins/licenceService";

export default {
  components: { YesNo },

  async created() {
    await this.setData();
  },

  data: () => ({
    certificatsMedicaux: null,
    filterData: [],
    confirmDialog: false,
    itemToDelete: null,
    questionnaire: [],
    fullCMandQS: [],
    licence: null
  }),
  computed: {
    ...mapGetters("user", ["getRole"]),
    ...mapGetters("saisons", ["currentSaison"]),

    isInPersonalAccount() {
      if (this.$route.path.includes("compte")) return true;

      return false;
    },
    isAdmin() {
      return [
        RoleTypes.Administrateur,
        RoleTypes.AdministrateurVieTerritoriale,
        RoleTypes.ClubAdministrateur,
        RoleTypes.EtablissementAffilieAdministrateur,
      ].includes(this.getRole?.id);
    },
  },

  methods: {
    moment,
    ...mapActions("basics", [
      'updateLicenceStatus'
    ]),
    ...mapActions("utilisateurs", [
      "getUtilisateurCertificatsM",
      "deleteUtilisateurCerttificatsM",
      "getUtilisateurQuestionnaireSante",
    ]),
    getCoveredSeason(item) {
      const debutDate = parseInt(new Date(item.DateValidation).getFullYear());

      if (item.Categorie === "Questionnaire santé" || debutDate < 2014) {
        return [parseInt(new Date(item.DateFinValiditeDocument).getFullYear())];
      }
      const finDate = parseInt(
        new Date(item.DateFinValiditeDocument).getFullYear()
      );
      let years = [];

      for (let i = debutDate; i <= finDate; i++) {
        years.push(i);
      }
      return years;
    },
    test(item) {
      console.log("item :>> ", item);
    },
    downloadCertificat(file) {
      const link = document.createElement("a");
      link.href = file.CheminDocument;
      link.download = `${this.context?.user?.UTI_Utilisateur?.CT_Nom}_${this.context?.user?.UTI_Utilisateur?.CT_Prenom}_certificat_medical.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async getCertificatMedical() {
      // we only take 1 questionnaire sante per season
      const allQuestionnaires = await this.getUtilisateurQuestionnaireSante(
        this.context
      );
      const allQuestionnaireWithSeason = allQuestionnaires.filter(
        (qc) => qc.ID_Saison && (qc.Categorie === "Questionnaire santé" || qc.ID_Type_Document === 60)
      );

      const uniqueSeasonsQS = new Set();
      for (const object of allQuestionnaireWithSeason) {
        uniqueSeasonsQS.add(object.ID_Saison);
      }
      const arraySeason = Array.from(uniqueSeasonsQS);
      let questionnaire = [];
      arraySeason.forEach((season) => {
        let temp = allQuestionnaireWithSeason.filter(
          (questionnaire) => questionnaire.ID_Saison === season
        );
        questionnaire.push(temp[0]);
      });
      const hasValidQuestionnaire = questionnaire.some(
        (qs) => qs.ID_Saison === this.currentSaison.id
      );
      this.$store.commit(
        "utilisateurs/SET_HAS_VALID_QS",
        hasValidQuestionnaire
      );
      return questionnaire;
    },
    async setData() {
      const certificat = await this.getUtilisateurCertificatsM(this.context);

      this.certificatsMedicaux = certificat.filter(
        (c) => c.Categorie !== "questionnaire"
      );

      const questionnare = await this.getCertificatMedical();

      this.fullCMandQS = [
        ...this.certificatsMedicaux,
        ...questionnare,
      ].sort((a, b) => (a.DateValidation < b.DateValidation ? 1 : -1));

      this.formatCertificat(this.certificatsMedicaux);

      const licences = await getLicences({
        user: `/api/uti_utilisateurs/${this.context.ID_Utilisateur}`,
        season: `/api/saison/${this.currentSaison.id}`,
        'order[dateEnd]': 'desc',
      });
      this.licence = licences[0];
    },
    addItem(item) {
      this.certificatsMedicaux.push(item);
    },
    renouvellement(cmType) {
      let slug = cmType.replace("certificat_medical_", "");
      this.$emit("renouvellement", slug);
    },
    showDialog(itemToDelete) {
      console.log(itemToDelete);
      this.confirmDialog = true;
      this.itemToDelete = itemToDelete;
    },
    async deleteCertificatMedical() {
      try {
        const response = await this.deleteUtilisateurCerttificatsM({
          idToDelete: this.itemToDelete.id,
        });

        if (response) {
          this.certificatsMedicaux = this.certificatsMedicaux.filter(
            (certificat) => certificat.id !== this.itemToDelete.id
          );
        }
      } catch (error) {
        error_notification("Erreur de suppression !");
        this.confirmDialog = false;
        return;
      }

      await this.updateLicenceStatus({id: this.licence.id, status: waitingDocument});

      this.$emit('docDelete');
      this.confirmDialog = false;
    },

    formatCertificat(certificatsMedicaux) {
      certificatsMedicaux.map((certificat) => {
        if (certificat && certificat.LIST_DocumentType.length != 0) {
          {
            {
              let documentType =
                certificat.LIST_DocumentType &&
                certificat.LIST_DocumentType.SlugTypeDocument;
              if (!documentType) return;
              if (documentType === "certificat_medical_competition") {
                certificat.LIST_DocumentType.NomTypeDocument = "Compétition";
              }
              if (documentType === "certificat_medical_loisir") {
                certificat.LIST_DocumentType.NomTypeDocument = "Loisir";
              }
            }
          }
        }
      });
    },

    canDeleteThisDoc(cm) {
      // Vérifier si l'cm est un Questionnaire santé
      if (cm.Categorie === 'Questionnaire santé') {
        return true;
      }
      // Récupérer tous les questionnaires de santé valides
      const validQuestionnaires = this.fullCMandQS.filter(qs =>
          qs.Categorie === 'Questionnaire santé' &&
          qs.EST_Actif &&
          qs.EST_DocumentValide &&
          new Date(qs.DateValidation) >= new Date(cm.DateValidation) &&
          new Date(qs.DateValidation) <= new Date(cm.DateFinValiditeDocument)
      );
      // Vérifier si le certificat médical a un questionnaire de santé valide associé
      // si il a un qs associé alors on ne permet pas la suppression
      return validQuestionnaires.length > 0 ? false : true;
    }
  },

  props: {
    context: {
      type: Object,
    },
    isCT: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.header-data {
  background-color: #39558b !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #ffffff !important;
}
.text-normal {
  font-family: Roboto;
  font-style: normal;
  /* font-weight: normal; */
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.panel-title {
  font-family: Roboto;
  font-style: normal;
  /* font-weight: normal; */
  font-size: 1rem;
  line-height: 24px;
  color: #000000;
}
.text3 {
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 20px;
  color: #ffffff;
}
::v-deep .v-text-field__details {
  display: none !important;
}
</style>
